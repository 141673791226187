import { handleApiError, Result } from "./utils";
import { ExtractErrorDetails } from "./Errorservice";

import {
  ExtractSortBy,
  ExtractFilterBy,
  requestOptionsForGET,
  requestOptionsForPATCH,
  requestOptionsForDELETE,
  requestOptionsForPOST,
  requestOptionsForRWAAPIGET,
} from "./CommonServices";

const baseUrl = process.env.REACT_APP_APPHUB_API_ENDPOINT;
//const baseUrl = process.env.APPHUB_API_ENDPOINT;

export const deleteUser = async (id) => {
  var options = requestOptionsForPATCH();
  const response = fetch(baseUrl + "user/delete/" + id, options).then(async (res) => {
    const data = await res.json();
    if (!res.ok) {
      const error = ExtractErrorDetails(res, data);
      return Promise.reject(error);
    }
    return data;
  });
  return response;
};

export const postUser = async (data) => {
  var options = requestOptionsForPOST(data);
  const response = fetch(baseUrl + "user", options)
    .then(async (res) => {
      const data = await res.json();
      // check for error response
      if (!res.ok) {
        // get error message from body or default to response statusText
        //const error = (data && data.message) || res.statusText;

        const error = ExtractErrorDetails(res, data);
        return Promise.reject(error);
      }
      return data;
    })
    //  .then(res => res.json())
    //  .then(
    //      (result) => {
    //          if (result !== null) {
    //             if (!result.ok) {
    //               // get error message from body or default to response statusText
    //               const error = (data && data.message) || result.statusText;
    //               return Promise.reject(error);
    //             }
    //             return result
    //          }
    //      })
    .catch((error) => {
      return Promise.reject(error);
    });
  return response;
};

export const fetchUser = async (id) => {
  var options = requestOptionsForGET();
  const response = fetch(baseUrl + "user/" + id, options).then((data) => {
    return data.json();
  });
  return response;
};

export const fetchRoleView = async (id) => {
  var options = requestOptionsForGET();
  const response = fetch(baseUrl + "user/RoleView/" + id, options).then(
    (data) => {
      return data.json();
    }
  );
  return response;
};

export const patchUser = async (data) => {
  var options = requestOptionsForPATCH(data);
  const response = fetch(baseUrl + "user/" + data.userid, options).then(
    async (res) => {
      const data = await res.json();
      // check for error response
      if (!res.ok) {
        // get error message from body or default to response statusText
        const error = ExtractErrorDetails(res, data);
        return Promise.reject(error);
      }
      return data;
    }
  );

  return response;
};

export const UpdateUserLoggedIn = async () => {
  let userLoggedInobj = {
    idpid: localStorage.getItem("idp_id"),
    starfleetid: localStorage.getItem("sub"),
    idpname: localStorage.getItem("idp_name"),
  };

  var options = requestOptionsForPATCH(userLoggedInobj);

  //const response = await fetch(baseUrl + "user/UpdateUserLoggedIn", options);

  // .then(async (res) => {
  //   return res;
  // });

  // return response;

  const response = await fetch(baseUrl + "user/UpdateUserLoggedIn", options);
  if (response.ok) {
    const data = await response.json();
    return { statusCode: response.status, data };
    // Result.success(data);
  } else {
    return { statusCode: response.status };
  }
  //   .then(async (res) => {
  //     const data = await res.json();
  //     // check for error response
  //     if (!res.ok) {
  //       const error = ExtractErrorDetails(res, data);
  //       return Promise.reject(error);
  //     }
  //     return data;
  //   })

  //   .catch((error) => {
  //     return Promise.reject(error);
  //   });
  // return response;
};

export const fetchUserList = async (
  pageIndex = 1,
  pageSize = 5,
  sorting = [],
  filtering = []
) => {
  const sortstr =
    sorting === undefined || sorting.length === 0 ? "" : ExtractSortBy(sorting);
  const filterstr =
    filtering === undefined || filtering.length === 0
      ? ""
      : ExtractFilterBy(filtering);

  var options = requestOptionsForGET();
  //const response = CustomAnswers
  let querystr = "";
  querystr =
    "?PageNumber=" + pageIndex + "&Limit=" + pageSize + sortstr + filterstr;

  const response = fetch(baseUrl + "user" + querystr, options)
    .then(async (res) => {
      const data = await res.json();

      if (!res.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || res.statusText;
        return Promise.reject(error);
      }
      return data;
    })
    .catch((error) => {
      console.error("There was an error!", error.toString());
    });
  return response;
};

export const fetchAdUserList = async (
  pageIndex = 1,
  pageSize = 5,
  sorting,
  filtering
) => {
  const sortstr =
    sorting === undefined || sorting.length === 0 ? "" : ExtractSortBy(sorting);

  let dynamicConditionGroups = [];
  dynamicConditionGroups = filtering;

  var options = requestOptionsForPOST(dynamicConditionGroups);
  //const response = CustomAnswers
  let querystr = "";
  querystr = "?PageNumber=" + pageIndex + "&Limit=" + pageSize + sortstr;

  const response = fetch(baseUrl + "user/GetAllUser" + querystr, options)
    //const response = fetch(baseUrl + "user", options)
    .then(async (res) => {
      const data = await Promise.resolve(res.json());

      if (!res.ok) {
        // get error message from body or default to response statusText
        const error = (data && data.message) || res.statusText;
        return Promise.reject(error);
      }
      return data;
    })
    .catch((error) => {
      console.error("There was an error!", error.toString());
    });
  return response;
};

export const GetUserAccess = async () => {
  try {
    var options = requestOptionsForGET();

    const response = await fetch(baseUrl + `user/GetUserAccess`, options);

    if (response.status === 200) {
      const data = await response.json();

      return Result.success(data);
      // } else {
      //   return handleApiError();
    } else {
      return handleApiError(response);
    }
  } catch (e) {
    return handleApiError(e);
  }
};
export const exportToExcel = async (filtering) => {
  try {
    var options = requestOptionsForPOST(filtering);

    const response = await fetch(baseUrl + "user/Exporttoexcel", options);
    // var options = requestOptionsForPOSTFileData();
    // const response = await fetch(baseUrl + `user/Exporttoexcel`, options);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return response;
    // const blob = await response.blob();
    // saveAs(blob, "example.xlsx");
  } catch (e) {
    return handleApiError(e);
  }
};
export const CheckUserExists = async () => {
  try {
    var options = requestOptionsForGET();

    // let queryparams = new URLSearchParams({ email });
    const response = await fetch(
      //   baseUrl + `userHelper/CheckUserExists?${queryparams}`,
      baseUrl + `user/CheckUserExists`,
      options
    );

    if (response.status === 200) {
      const data = await response.json();

      return Result.success(data);
    } else {
      return handleApiError(response);
    }
  } catch (e) {
    return handleApiError(e);
  }
};

export const CheckUserIsInternal = async () => {
  try {
    var options = requestOptionsForGET();

    const response = await fetch(baseUrl + `user/CheckUserIsInternal`, options);

    if (response.status === 200) {
      const data = await response.json();

      return Result.success(data);
    } else {
      return handleApiError(response);
    }
  } catch (e) {
    return handleApiError(e);
  }
};

export const fetchUserDetailsByEmail = async () => {
  var options = requestOptionsForGET();

  const response = await fetch(baseUrl + "user/GetUserDetailsbyEmail", options);
  if (response.ok) {
    const data = await response.json();
    return { statusCode: response.status, data };
    // Result.success(data);
  } else {
    return { statusCode: response.status };
  }
};
export const CheckUserExistsByEmail = (emailid) => {
  var options = requestOptionsForRWAAPIGET();
  let querystr = "?emailid=" + emailid;
  const response = fetch(
    process.env.REACT_APP_RWA_API_ENDPOINT +
      "user/getuserdetailsbyid" +
      querystr,
    options
  ).then((res) => {
    // const data = res

    // if (!res.ok) {
    //   return data; //Promise.reject(error)
    // }
    return res;
  });
  return response;
};
