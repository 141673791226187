import {
  ExtractSortBy,
  ExtractFilterBy,
  requestOptionsForGET,
} from "./CommonServices";

const baseUrl = process.env.REACT_APP_APPHUB_API_ENDPOINT;
//const baseUrl = process.env.APPHUB_API_ENDPOINT;

export const fetchAuditLog = async (id) => {
  var options = requestOptionsForGET();

  const response = fetch(baseUrl + "audits/" + id, options).then((data) => {
    return data.json();
  });
  return response;
};

export const fetchAuditLogList = async (
  pageIndex = 1,
  pageSize = 5,
  sorting = [],
  filtering = []
) => {
  const sortstr =
    sorting === undefined || sorting.length === 0 ? "" : ExtractSortBy(sorting);
  const filterstr =
    filtering === undefined || filtering.length === 0
      ? ""
      : ExtractFilterBy(filtering);

  var options = requestOptionsForGET();

  //const response = CustomAnswers
  let querystr = "";
  querystr =
    "?PageNumber=" + pageIndex + "&Limit=" + pageSize + sortstr + filterstr;
  const response = fetch(baseUrl + "audits/" + querystr, options)
    .then(async (res) => {
      const data = await res.json();
      // check for error response
      if (!res.ok) {
        return data; //Promise.reject(error)
      }
      return data;
    })
    .catch((error) => {
      console.error("There was an error!", error.toString());
    });
  return response;
};
export const fetchAuditLogsByEmail = async (email) => {
  var options = requestOptionsForGET();

  //const response = CustomAnswers
  let querystr = "?email=" + email;

  const response = fetch(
    baseUrl + "audits/GetAuditLogsByEmail" + querystr,
    options
  )
    .then(async (res) => {
      const data = await res.json();

      if (!res.ok) {
        return data;
      }
      return data;
    })
    .catch((error) => {
      console.error("There was an error!", error.toString());
    });
  return response;
};
