export const getLogicalOperator = () => {
  return [
    {
      label: "And",
      value: "AND",
    },
    {
      label: "OR",
      value: "OR",
    },
  ];
};

export const getColumnNames = () => {
  return [
    {
      label: "Email",
      value: "Emailid",
    },
    {
      label: "Company",
      value: "Companyname",
    },
    {
      label: "Pec Id",
      value: "Pecid",
    },
    {
      label: "Access Role(s)",
      value: "Accessroles",
    },
    {
      label: "Last Name",
      value: "Lastname",
    },
    {
      label: "First Name",
      value: "Firstname",
    },
    {
      label: "Country Code",
      value: "Countrycode",
    },
    {
      label: "Idp Name",
      value: "Idpname",
    },
    {
      label: "IDM Access Code",
      value: "Idmaccesscode",
    },
    {
      label: "Product Family",
      value: "Productfamily",
    },
    {
      label: "License Type",
      value: "Licensetype",
    },
    {
      label: "User Name",
      value: "Username",
    },
    {
      label: "Disable Status",
      value: "Isdisablestatus",
    },
    {
      label: "Created date",
      value: "Createddate",
    },
    {
      label: "Last update",
      value: "Modifieddate",
    },
  ];
};
export const getOperators = () => {
  return [
    {
      label: "=",
      value: "=",
    },
    {
      label: "!=",
      value: "!=",
    },
    {
      label: ">",
      value: ">",
    },
    {
      label: ">=",
      value: ">=",
    },
    {
      label: "<",
      value: "<",
    },
    {
      label: "<=",
      value: "<=",
    },
    {
      label: "startswith",
      value: "startswith",
    },
    {
      label: "contains",
      value: "contains",
    },
    {
      label: "endswith",
      value: "endswith",
    },
  ];
};
