import { useContext, useEffect, useState } from "react";
import Spinner from "@kaizen-ui/spinner";
import {
  fetchUser,
  postUser,
  patchUser,
  deleteUser,
  fetchRoleView,
} from "../../../api";
import { Layout } from "../../../layout";
import { useParams } from "react-router-dom";
import { Field, Formik } from "formik";
import Block from "@kaizen-ui/block";
import Button from "@kaizen-ui/button";
import Textbox from "@kaizen-ui/textbox";
import "./styles.css";
import { BlockSection } from "@kaizen-ui/block";
import ButtonGroup from "@kaizen-ui/button";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { string, object } from "yup";

import "./styles.css";
import { Banner, KaizenThemeContext } from "@kaizen-ui/complete";
import RoleSelectorTable from "../../../components/roleTable";
import { Checkbox } from "@kui-react/checkbox";
import AccessibilityContext from "../../../components/auth/UserPermissionContext";
import WithAuth from "../../../components/auth/WithAuth";
import WithAdminUIAuth from "../../../components/auth/WithAdminUIAuth";

const Loading = () => (
  <Layout title="Loading...">
    <Spinner className="loading-spinner" size="small" />
  </Layout>
);
const initstate = {
  userid: 0,
  username: "",
  emailid: "",
};
const validationSchema = object().shape({
  username: string().required("User name is required."),
  emailid: string().required("User email is required."),
  firstname: string().required("First name is required."),
  lastname: string().required("User email is required."),
  // idpname: string().required("Idp name is required."),
  // idpid: string().required("Idp Id is required."),
  // companyname: string().required("Company name is required."),
  countrycode: string().required("Country Code is required."),
  // starfleetid: string().required("Starfleet Id is required."),
  pecid: string().required("Pec Id is required."),
  productfamily: string().required("Product Family is required."),
  licensetype: string().required("License Type is required."),
  idmaccesscode: string().required("idm Access code is required."),
});

const UserDetail = () => {
  let { id } = useParams();
  const [data, setData] = useState();
  const [isrestricted, setisrestrictedData] = useState(false);
  const [isActive, setisActiveData] = useState(false);
  const [error, setError] = useState();
  const [errormsg, setErrorMsg] = useState("");
  const [initialValues] = useState(initstate);
  const [success, setSuccess] = useState("");
  const [roleDescription, setRoleDescription] = useState("");
  const { colors } = useContext(KaizenThemeContext);

  const [items, setItems] = useState([]);
  const [isDisable, setisDisable] = useState(false);
  //accessebility
  const permissions = useContext(AccessibilityContext).filter(
    (x) => x.pagename === "user"
  )[0];

  const permissionsData = useContext(AccessibilityContext);

  const toggleCheckbox = (index) => (e) => {
    let nextState = [...items];

    let val = e.target.checked;
    if (index > -1) {
      nextState[index].isChecked = val;
    }

    setItems(nextState);
    setRoleDescription(getSelectedRolesDescription(nextState));
  };

  const toggleCheckboxEvent = () => (e) => {
    setisrestrictedData(e.target.checked);
  };

  const toggleIsactiveCheckboxEvent = () => (e) => {
    setisActiveData(e.target.checked);
  };

  const getSelectedValues = () => {
    const selectedValues = items
      .filter((item) => item.isChecked)
      .map((item) => item.roleid);
    return selectedValues;
  };

  const getSelectedRolesDescription = (roles) => {
    let selectedValues = "";
    selectedValues = roles
      .filter((item) => item.isChecked)
      .map((item) => item.rolename);

    return selectedValues.join(",");
  };

  function errorHandler(error, setError, setErrorMsg) {
    setErrorMsg(error.status_message);
    setError(error);
    setTimeout(() => {
      setError(undefined);
    }, 5000);
  }
  function SuccessHandler(setSuccess, setData, data, navigate) {
    setTimeout(() => {
      setSuccess("");
      if (data !== undefined) {
        //setData(data);
        navigate("/user");
        // navigate("/user/" + data.userid);
        // id = data.userid.toString();
      } else {
        navigate("/user");
      }
    }, 2000);
  }

  const disableDelete = () => {
    if (data && permissions?.delete) {
      if (data.userid > 0) return false;
    }
    return true;
  };

  const disableAuditLog = () => {
    if (data.userid > 0) return false;

    return true;
  };

  const disableEdit = () => {
    return !(permissions?.create || (permissions?.update && id > 0));
  };
  const disableEditField = (fieldname) => {
    var per = permissionsData.filter((x) => x.pagename === fieldname)[0];
    var v = !(
      (permissions?.create || (permissions?.update && id > 0)) &&
      (permissionsData === undefined || per?.update)
    );
    return v;
  };

  const deleteUserData = () => {
    if (id !== undefined) {
      var bconfirm = window.confirm("Do you want to delete the user?");
      if (bconfirm) {
        deleteUser(parseInt(id))
          .then((data) => {
            setSuccess("Successfully deleted user - " + id);
            SuccessHandler(setSuccess, undefined, undefined, navigate);
          })
          .catch((error) => {
            errorHandler(error, setError, setErrorMsg);
          })
          .finally(() => {});
      }
    }
  };
  function GetTodayDateString() {
    const currentDate = new Date();
    const options = {
      year: "numeric",
      month: "long",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    const formattedDate = currentDate.toLocaleString("en-US", options);

    return formattedDate.replace("at", "");
  }
  // Function to remove duplicates and update the state
  const removeDuplicates = (roles) => {
    const stringWithoutSpaces = roles.replace("\n", ",");
    const roleDescription = stringWithoutSpaces
      .replace(" & ", ",")
      .replace("&", ",")
      .replace(" & ", ",");

    const array1 = roleDescription.split(",");
    const trimmedArray = array1.map((str) => str.trim());

    const uniqueArray = trimmedArray.filter(
      (item, index) => trimmedArray.indexOf(item) === index
    );
    // Convert the array to a string with '\n' as the separator
    const stringData = uniqueArray.join("\n");

    return stringData;
  };

  useEffect(() => {
    setisDisable(permissions?.create || (permissions?.update && id > 0));

    if (id === "0") {
      setData({
        userid: 0,
        username: "",
        emailid: "",
        firstname: "",
        lastname: "",
        companyname: "",
        countrycode: "",
        starfleetid: "",
        idpname: "",
        idpid: "",
        pecid: 0,
        licensetype: "",
        productfamily: "",
        idmaccesscode: "",
        createddatetime: GetTodayDateString(),
      });
      setisActiveData(true);
      fetchRoleView(Number(0)).then((data) => {
        setItems(data);
      });
    } else {
      fetchRoleView(Number(id)).then((data) => {
        setRoleDescription(getSelectedRolesDescription(data));

        setItems(data);
      });

      fetchUser(Number(id)).then((data) => {
        setisrestrictedData(data.earrestricted);
        setisActiveData(data.isactive);
        setData(data);
      });
    }
  }, [id]);

  const navigate = useNavigate();
  if (!data) return <Loading />;

  if (permissions != null && permissions !== undefined && !permissions.read) {
    navigate("/unauthorized");
  }

  if (permissions?.read) {
    return (
      <Formik
        initialValues={data !== null ? data : initialValues}
        initialTouched={{
          userid: true,
          username: true,
          emailid: true,
        }}
        onReset={(values) => {
          values = data;
        }}
        onSubmit={(values, actions) => {
          actions.setSubmitting(true);
          // buildrequest(values)
          if (id === "0") {
            const createdata = values;
            createdata.isactive = isActive;
            createdata.earrestricted = isrestricted;
            createdata.Roles = getSelectedValues();

            setError(undefined);
            postUser(createdata)
              .then((data) => {
                setSuccess("Successfully Created ");
                SuccessHandler(setSuccess, setData, data, navigate);
              })
              .catch((error) => {
                errorHandler(error, setError, setErrorMsg);
              })
              .finally(() => {
                actions.setSubmitting(false);
              });
          } else {
            const updatedata = values;
            updatedata.earrestricted = isrestricted;
            updatedata.isactive = isActive;
            updatedata.Roles = getSelectedValues();

            patchUser(updatedata)
              .then((data) => {
                setSuccess("Successfully Updated ");
                SuccessHandler(setSuccess, setData, data, navigate);
              })
              .catch((error) => {
                errorHandler(error, setError, setErrorMsg);
              })
              .finally(() => {
                actions.resetForm();
                actions.setSubmitting(false);
              });
          }
        }}
        validationSchema={validationSchema}
        validateOnMount
      >
        {(formikProps) => (
          <Layout
            title="User"
            headerChildren={
              <ButtonGroup>
                <Button
                  icon={{ name: "PlaybackCircleRewind", variant: "solid" }}
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Go Back
                </Button>
              </ButtonGroup>
            }
          >
            {error && error !== undefined ? (
              // <Result status="error" title="Error" subTitle={JSON.stringify(errormsg)}  />
              <Banner
                className="bannertofront"
                rounded
                status="critical"
                title="Error"
              >
                {JSON.stringify(errormsg)}
              </Banner>
            ) : (
              ""
            )}
            {success !== "" ? (
              <Banner
                className="bannertofront"
                rounded
                status="success"
                title="Success"
              >
                {success}
              </Banner>
            ) : (
              //<Result status="success" title={success} />
              ""
            )}
            <Block
              inline
              className="form-example"
              loading={formikProps.isSubmitting}
            >
              <table style={{ width: "100%" }}>
                <tr>
                  <td>
                    {" "}
                    <BlockSection
                      size="full"
                      className="hubtagfieldblocksection"
                    >
                      <h4
                        className="hubtagfieldh4"
                        style={{ color: colors.fontColor }}
                      >
                        First Name<span className="hubtagfieldspan">*</span>
                      </h4>
                      <Field
                        as={Textbox}
                        className="zero-left zero-right hubtagfieldtext"
                        disabled={disableEditField("firstname")}
                        name="firstname"
                        values={formikProps.values.firstname}
                        placeholder="please enter first name"
                        pattern={formikProps.errors.firstname}
                        validationMessage={formikProps.errors.firstname}
                        required={true}
                        valid={true}
                        showValidIcon={true}
                      />
                    </BlockSection>
                  </td>
                  <td>
                    {" "}
                    <BlockSection
                      size="full"
                      className="hubtagfieldblocksection"
                    >
                      <h4
                        className="hubtagfieldh4"
                        style={{ color: colors.fontColor }}
                      >
                        Last Name<span className="hubtagfieldspan">*</span>
                      </h4>
                      <Field
                        as={Textbox}
                        className="zero-left zero-right hubtagfieldtext"
                        disabled={disableEditField("lastname")}
                        name="lastname"
                        values={formikProps.values.lastname}
                        placeholder="please enter last name"
                        pattern={formikProps.errors.lastname}
                        validationMessage={formikProps.errors.lastname}
                        required={true}
                        valid={true}
                        showValidIcon={true}
                      />
                    </BlockSection>
                  </td>
                  <td>
                    <BlockSection
                      size="full"
                      className="hubtagfieldblocksection"
                    >
                      <h4
                        className="hubtagfieldh4"
                        style={{ color: colors.fontColor }}
                      >
                        User Name<span className="hubtagfieldspan">*</span>
                      </h4>
                      <Field
                        as={Textbox}
                        className="zero-left zero-right hubtagfieldtext"
                        disabled={disableEditField("username")}
                        name="username"
                        values={formikProps.values.username}
                        placeholder="please enter user name"
                        pattern={formikProps.errors.username}
                        validationMessage={formikProps.errors.username}
                        required={true}
                        valid={true}
                        showValidIcon={true}
                      />
                    </BlockSection>
                  </td>
                  <td>
                    <BlockSection
                      size="full"
                      className="hubtagfieldblocksection"
                    >
                      <h4
                        className="hubtagfieldh4"
                        style={{ color: colors.fontColor }}
                      >
                        Email <span className="hubtagfieldspan">*</span>
                      </h4>
                      <Field
                        as={Textbox}
                        className="zero-left zero-right hubtagfieldtext"
                        disabled={disableEditField("emailid")}
                        name="emailid"
                        values={formikProps.values.emailid}
                        placeholder="please enter email"
                        pattern={formikProps.errors.emailid}
                        validationMessage={formikProps.errors.emailid}
                        required={true}
                        valid={true}
                        showValidIcon={true}
                      />
                    </BlockSection>
                  </td>
                </tr>
                <tr>
                  <td>
                    <BlockSection
                      size="full"
                      className="hubtagfieldblocksection"
                    >
                      <h4
                        className="hubtagfieldh4"
                        style={{ color: colors.fontColor }}
                      >
                        Company Name
                      </h4>
                      <Field
                        as={Textbox}
                        className="zero-left zero-right hubtagfieldtext"
                        disabled={disableEditField("companyname")}
                        name="companyname"
                        values={formikProps.values.companyname}
                        placeholder="please enter company name"
                        pattern={formikProps.errors.companyname}
                        validationMessage={formikProps.errors.companyname}
                        // required={true}
                        valid={true}
                        showValidIcon={true}
                      />
                    </BlockSection>
                  </td>
                  <td>
                    <BlockSection
                      size="full"
                      className="hubtagfieldblocksection"
                    >
                      <h4
                        className="hubtagfieldh4"
                        style={{ color: colors.fontColor }}
                      >
                        Pec Id <span className="hubtagfieldspan">*</span>
                      </h4>
                      <Field
                        as={Textbox}
                        className="zero-left zero-right hubtagfieldtext"
                        disabled={disableEditField("pecid")}
                        name="pecid"
                        values={formikProps.values.pecid}
                        placeholder="please enter Pec Id"
                        pattern={formikProps.errors.pecid}
                        validationMessage={formikProps.errors.pecid}
                        required={true}
                        valid={true}
                        showValidIcon={true}
                      />
                    </BlockSection>
                  </td>
                  <td style={{ verticalAlign: "top" }}>
                    <BlockSection
                      size="full"
                      className="hubtagfieldblocksection"
                    >
                      <h4
                        className="hubtagfieldh4"
                        style={{ color: colors.fontColor }}
                      >
                        Country Code <span className="hubtagfieldspan">*</span>
                      </h4>
                      <Field
                        as={Textbox}
                        className="zero-left zero-right hubtagfieldtext"
                        disabled={disableEditField("countrycode")}
                        name="countrycode"
                        values={formikProps.values.countrycode}
                        placeholder="please enter   Country Code"
                        pattern={formikProps.errors.countrycode}
                        validationMessage={formikProps.errors.countrycode}
                        required={true}
                        valid={true}
                        showValidIcon={true}
                      />
                    </BlockSection>
                  </td>
                  <td>
                    <table>
                      <tr>
                        <td>
                          <BlockSection
                            size="full"
                            className="hubtagfieldblocksection"
                          >
                            <h4
                              className="hubtagfieldh4"
                              style={{ color: colors.fontColor }}
                            >
                              IS Restricted{" "}
                              <span className="hubtagfieldspan">*</span>
                            </h4>

                            <Checkbox
                              value="isrestricted"
                              disabled={disableEditField("isrestricted")}
                              checked={isrestricted}
                              id="isrestricted1"
                              onChange={toggleCheckboxEvent()}
                            />
                          </BlockSection>
                        </td>
                        <td>
                          <BlockSection
                            size="full"
                            className="hubtagfieldblocksection"
                          >
                            <h4
                              className="hubtagfieldh4"
                              style={{ color: colors.fontColor }}
                            >
                              Enabled
                            </h4>

                            <Checkbox
                              value={formikProps.values.isactive}
                              checked={isActive}
                              onChange={toggleIsactiveCheckboxEvent()}
                              disabled={disableEditField("isActive")}
                              id="useraccountstatus"
                              name="useraccountstatus"
                            />
                          </BlockSection>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>

                <tr>
                  <td style={{ verticalAlign: "top" }} colSpan={2}>
                    <BlockSection
                      size="full"
                      className="hubtagfieldblocksection"
                    >
                      <h2
                        className="hubtagfieldh4"
                        style={{ color: colors.fontColor }}
                      >
                        Role Description
                      </h2>
                      <Field
                        as={Textbox}
                        inputMode="text"
                        inputType="multiLine"
                        disabled={true}
                        name="roleDescription1"
                        value={removeDuplicates(roleDescription)}
                        valid={true}
                        showValidIcon={true}
                      />
                    </BlockSection>
                  </td>
                  <td style={{ verticalAlign: "top" }}>
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td>
                          {" "}
                          <BlockSection
                            size="full"
                            className="hubtagfieldblocksection"
                          >
                            <h4
                              className="hubtagfieldh4"
                              style={{ color: colors.fontColor }}
                            >
                              Createdby
                            </h4>
                            <Field
                              as={Textbox}
                              className="zero-left zero-right hubtagfieldtext"
                              disabled={true}
                              name="createdbyuser"
                              values={formikProps.values.createdbyuser}
                              valid={true}
                              showValidIcon={true}
                            />
                          </BlockSection>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <BlockSection
                            size="full"
                            className="hubtagfieldblocksection"
                          >
                            <h4
                              className="hubtagfieldh4"
                              style={{ color: colors.fontColor }}
                            >
                              Date Created
                            </h4>
                            <Field
                              as={Textbox}
                              className="zero-left zero-right hubtagfieldtext"
                              disabled={true}
                              name="createddatetime"
                              values={formikProps.values.createddatetime}
                              valid={true}
                              showValidIcon={true}
                            />
                          </BlockSection>
                        </td>
                      </tr>
                    </table>
                  </td>
                  <td style={{ verticalAlign: "top" }}>
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td>
                          {" "}
                          <BlockSection
                            size="full"
                            className="hubtagfieldblocksection"
                          >
                            <h4
                              className="hubtagfieldh4"
                              style={{ color: colors.fontColor }}
                            >
                              Modifiedby
                            </h4>
                            <Field
                              as={Textbox}
                              className="zero-left zero-right hubtagfieldtext"
                              disabled={true}
                              name="modifiedbyuser"
                              values={formikProps.values.modifiedbyuser}
                              valid={true}
                              showValidIcon={true}
                            />
                          </BlockSection>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {" "}
                          <BlockSection
                            size="full"
                            className="hubtagfieldblocksection"
                          >
                            <h4
                              className="hubtagfieldh4"
                              style={{ color: colors.fontColor }}
                            >
                              Modified Date
                            </h4>
                            <Field
                              as={Textbox}
                              className="zero-left zero-right hubtagfieldtext"
                              disabled={true}
                              name="modifieddatetime"
                              values={formikProps.values.modifieddatetime}
                              valid={true}
                              showValidIcon={true}
                            />
                          </BlockSection>
                        </td>
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>
                    <BlockSection
                      size="full"
                      className="hubtagfieldblocksection"
                    >
                      <h4
                        className="hubtagfieldh4"
                        style={{ color: colors.fontColor }}
                      >
                        Last Login Date
                      </h4>
                      <Field
                        as={Textbox}
                        className="zero-left zero-right hubtagfieldtext"
                        disabled={true}
                        name="logindatetime"
                        values={formikProps.values.logindatetime}
                        valid={true}
                        showValidIcon={true}
                      />
                    </BlockSection>
                  </td>
                  <td>
                    <BlockSection
                      size="full"
                      className="hubtagfieldblocksection"
                    >
                      <h4
                        className="hubtagfieldh4"
                        style={{ color: colors.fontColor }}
                      >
                        Idp Name
                      </h4>
                      <Field
                        as={Textbox}
                        className="zero-left zero-right hubtagfieldtext"
                        disabled={disableEditField("idpname")}
                        name="idpname"
                        values={formikProps.values.idpname}
                        placeholder="please enter idpname"
                        pattern={formikProps.errors.idpname}
                        // validationMessage={formikProps.errors.idpname}
                        // required={true}
                        valid={true}
                        showValidIcon={true}
                      />
                    </BlockSection>
                  </td>
                  <td>
                    {" "}
                    <BlockSection
                      size="full"
                      className="hubtagfieldblocksection"
                    >
                      <h4
                        className="hubtagfieldh4"
                        style={{ color: colors.fontColor }}
                      >
                        Idp Id
                      </h4>
                      <Field
                        as={Textbox}
                        className="zero-left zero-right hubtagfieldtext"
                        disabled={disableEditField("idpid")}
                        name="idpid"
                        values={formikProps.values.idpid}
                        placeholder="please enter idpid"
                        pattern={formikProps.errors.idpid}
                        validationMessage={formikProps.errors.idpid}
                        // required={true}
                        valid={true}
                        showValidIcon={true}
                      />
                    </BlockSection>
                  </td>
                  <td>
                    {" "}
                    <BlockSection
                      size="full"
                      className="hubtagfieldblocksection"
                    >
                      <h4
                        className="hubtagfieldh4"
                        style={{ color: colors.fontColor }}
                      >
                        Starfleet Id
                      </h4>
                      <Field
                        as={Textbox}
                        className="zero-left zero-right hubtagfieldtext"
                        disabled={disableEditField("starfleetid")}
                        name="starfleetid"
                        values={formikProps.values.starfleetid}
                        placeholder="please enter starfleetid"
                        pattern={formikProps.errors.starfleetid}
                        validationMessage={formikProps.errors.starfleetid}
                        // required={true}
                        valid={true}
                        showValidIcon={true}
                      />
                    </BlockSection>
                  </td>
                </tr>
                {/* <tr>
                  <td></td>
                </tr> */}

                <tr>
                  <td>
                    {" "}
                    <BlockSection
                      size="full"
                      className="hubtagfieldblocksection"
                    >
                      <h4
                        className="hubtagfieldh4"
                        style={{ color: colors.fontColor }}
                      >
                        License Type <span className="hubtagfieldspan">*</span>
                      </h4>
                      <Field
                        as={Textbox}
                        className="zero-left zero-right hubtagfieldtext"
                        disabled={disableEditField("licensetype")}
                        name="licensetype"
                        values={formikProps.values.licensetype}
                        placeholder="please enter license type"
                        pattern={formikProps.errors.licensetype}
                        validationMessage={formikProps.errors.licensetype}
                        required={true}
                        valid={true}
                        showValidIcon={true}
                      />
                    </BlockSection>
                  </td>
                  <td>
                    {" "}
                    <BlockSection
                      size="full"
                      className="hubtagfieldblocksection"
                    >
                      <h4
                        className="hubtagfieldh4"
                        style={{ color: colors.fontColor }}
                      >
                        Product Family{" "}
                        <span className="hubtagfieldspan">*</span>
                      </h4>
                      <Field
                        as={Textbox}
                        className="zero-left zero-right hubtagfieldtext"
                        disabled={disableEditField("productfamily")}
                        name="productfamily"
                        values={formikProps.values.productfamily}
                        placeholder="please enter productfamily"
                        pattern={formikProps.errors.productfamily}
                        validationMessage={formikProps.errors.productfamily}
                        required={true}
                        valid={true}
                        showValidIcon={true}
                      />
                    </BlockSection>
                  </td>
                  <td>
                    <BlockSection
                      size="full"
                      className="hubtagfieldblocksection"
                    >
                      <h4
                        className="hubtagfieldh4"
                        style={{ color: colors.fontColor }}
                      >
                        IDM Access Code{" "}
                        <span className="hubtagfieldspan">*</span>
                      </h4>
                      <Field
                        as={Textbox}
                        className="zero-left zero-right hubtagfieldtext"
                        disabled={disableEditField("idmaccesscode")}
                        name="idmaccesscode"
                        values={formikProps.values.idmaccesscode}
                        placeholder="please enter idm access code"
                        pattern={formikProps.errors.idmaccesscode}
                        validationMessage={formikProps.errors.idmaccesscode}
                        required={true}
                        valid={true}
                        showValidIcon={true}
                      />
                    </BlockSection>
                  </td>
                  <td></td>
                </tr>
              </table>

              <BlockSection size="full">
                <h4
                  className="hubtagfieldh4"
                  style={{ color: colors.fontColor }}
                >
                  Roles
                </h4>
                {/* <h4
                  className="hubtagfieldh4"
                  style={{
                    color: "#fff",
                    // height: "40px",
                    padding: "3px",
                    textAlign: "center",
                    fontSize: "15px",
                    backgroundColor: "rgb(112,181,0)",
                  }}
                >
                  User Role Selector
                </h4> */}

                <RoleSelectorTable
                  disable={disableEditField("accessroles")}
                  items={items}
                  toggleCheckbox={toggleCheckbox}
                ></RoleSelectorTable>
              </BlockSection>

              <table style={{ width: "100%", textAlign: "left" }}>
                <tr>
                  <td style={{ width: "10%", textAlign: "left" }}>
                    <Button
                      className="formsubmitbtn"
                      type="critical"
                      disabled={disableDelete()}
                      onClick={(e) => deleteUserData()}
                    >
                      Disable User
                    </Button>
                  </td>
                  <td style={{ width: "90%", textAlign: "left" }}>
                    {" "}
                    <BlockSection size="full">
                      <Button
                        className="formsubmitbtn"
                        type="critical"
                        onClick={() => navigate("/user")}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="formsubmitbtn"
                        disabled={disableEdit()}
                        onClick={() => {
                          formikProps.isSubmitting = true;
                          formikProps.handleSubmit();
                        }}
                      >
                        Submit
                      </Button>
                      <Button
                        className="formsubmitbtn"
                        onClick={(e) =>
                          navigate(`/userauditlog/${data.emailid}`)
                        }
                        disabled={disableAuditLog()}
                      >
                        User Audit log
                      </Button>{" "}
                    </BlockSection>
                  </td>
                </tr>
              </table>
            </Block>
          </Layout>
        )}
      </Formik>
    );
  }
};

export default WithAuth(WithAdminUIAuth(UserDetail));
